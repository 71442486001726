import React, { useState, useEffect } from "react";
import MainContent from "@components/content";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import logo from '@assets/logo.jpg';
import russia from '@assets/russia.svg';
import Logos from '@components/companies';
import ProjectBlock from "@components/projectMini";
import ReviewBlock from "@components/review";

import reactLogo from '@assets/companies/react.svg';
import jsLogo from '@assets/companies/js.svg';
import vueLogo from '@assets/companies/vue.svg';
import tsLogo from '@assets/companies/ts.svg';
import jqueryLogo from '@assets/companies/jquery.svg';

import nodejsLogo from '@assets/companies/nodejs.svg';
import phpLogo from '@assets/companies/php.svg';
import csharpLogo from '@assets/companies/csharp.svg';
import sqlLogo from '@assets/companies/sql.svg';

import figmaLogo from '@assets/companies/figma.svg';
import chatGptLogo from '@assets/companies/chatgpt.svg';
import notionLogo from '@assets/companies/notion.svg';

import kworkLogo from '@assets/companies/kwork.svg';

import LeeCyberimg from '@assets/leecyber.png';
import Metaimg from '@assets/meta.png';

import ArtConsimg from '@assets/artcons.png';
import LexMarcimg from '@assets/lexmarc.png';

import Popup from "../../components/contact";
import { useTranslation } from 'react-i18next';
import i18n from '@locales/index';

export default function RootPage() {
  const [available, setAvaiable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <MainContent>
        <Popup showModal={showModal} setShowModal={setShowModal} />
        <div className={css.hello}>
            {available ? 
                <div className={css.workAvailable}><div className={css.greenCircle} /> {t('main.available')}</div>
                :
                <div className={css.workAvailable}><div className={css.redCircle} /> {t('main.notAvailable')}</div>
            }
            <h1>{t('main.i1')} <img className={`${css.avatar} dev-hidden-mobile`} src={logo} /> {t('main.i2')} <img src={russia} width={50} />{t('main.i3')}</h1>
            <a>{t('main.desc')}</a>
            <Logos />
        </div>

        <div className={css.projects} id="projects">
            <div className={css.sectionName}>
                <h2>{t('main.projects')}</h2>
                <a>{t('main.projectsDesc')}</a>
            </div>

            <div className={css.projectsList}>
                <div className={css.projectFlex}>
                    <ProjectBlock 
                        name="LeeCyber"
                        mName={t('main.bizEco')}
                        desc={t('main.lcDesc')}
                        img={LeeCyberimg}
                        tag={t('main.startup')}
                        link="https://leecyber.com/"
                    />

                    <ProjectBlock 
                        name="Metaverse Project"
                        mName={t('main.metaM')}
                        desc={t('main.metaDesc')}
                        img={Metaimg}
                        tag={t('main.startup')}
                        link="https://gta5meta.com/"
                    />
                </div>

                <div className={css.projectFlex} style={{ marginTop: 50 }}>
                    <ProjectBlock 
                        name="ArtCons"
                        mName={t('main.artCons')}
                        desc={t('main.artConsM')}
                        img={ArtConsimg}
                        tag={t('main.startup')}
                        link="https://artcons.io/"
                    />

                    <ProjectBlock 
                        name="LExMARC"
                        mName={t('main.lexMarc')}
                        desc={t('main.lexMarcM')}
                        img={LexMarcimg}
                        tag={t('main.startup')}
                        link="https://lexmarc.ru/"
                    />
                </div>

                <div className={css.otherProjects}>
                    <a className={css.name}>{t('main.otherProjects')}</a>
                    {t('main.otherProjectsArray', { returnObjects: true }).map((project, index) => (
                        <div key={index}>
                            <Link className={css.prName} to={project.link || '#'}>{project.name} {project.link && <i className="fi fi-rr-arrow-up-right"></i>}</Link>
                            <a className={`${css.prWork} dev-hidden-mobile`}>{project.description}</a>
                            <a className={`${css.prType} dev-hidden-mobile`}>{project.type}</a>
                            <a className={`${css.prDate} dev-hidden-mobile`}>{project.date} {project.status != 0 ? <section className={css.greenCircle} data-tooltip={t('main.projectCompleted')}/> : <section className={css.whiteCircle} data-tooltip={t('main.projectInProgress')}/>}</a>
                        </div>
                    ))}
                </div>
            </div>
        </div>

        <div className={css.competition} id="competition">
            <div className={css.sectionName}>
                <h2>{t('competition.title')}</h2>
                <a>{t('competition.subtitle')}</a>
            </div>
            
            <div className={css.cList}>
                <div className={css.competitionInfo}>
                    <h4>{t('competition.frontend.title')}</h4>
                    <a className={css.desc}>{t('competition.frontend.desc')}</a>

                    <div className={css.instList}>
                        <div>
                            <img src={reactLogo} />
                            <a>React</a>
                        </div>

                        <div>
                            <img src={vueLogo} />
                            <a>VueJS</a>
                        </div>
                        
                        <div>
                            <img src={jqueryLogo} />
                            <a>jQuery</a>
                        </div>
                        
                        <div>
                            <img src={jsLogo} />
                            <a>JavaScript</a>
                        </div>

                        <div>
                            <img src={tsLogo} />
                            <a>TypeScript</a>
                        </div>

                        <div>
                            <img src={chatGptLogo} />
                            <a>ChatGPT</a>
                        </div>
                    </div>
                </div>

                <div className={css.competitionInfo}>
                    <h4>{t('competition.backend.title')}</h4>
                    <a className={css.desc}>{t('competition.backend.desc')}</a>

                    <div className={css.instList}>
                        <div>
                            <img src={nodejsLogo} />
                            <a>Node.js</a>
                        </div>

                        <div>
                            <img src={phpLogo} />
                            <a>PHP</a>
                        </div>
                        
                        <div>
                            <img src={csharpLogo} />
                            <a>C#</a>
                        </div>

                        <div>
                            <img src={sqlLogo} />
                            <a>СУБД</a>
                        </div>

                        <div>
                            <img src={chatGptLogo} />
                            <a>ChatGPT</a>
                        </div>
                    </div>
                </div>

                <div className={css.competitionInfo}>
                    <h4>{t('competition.design.title')}</h4>
                    <a className={css.desc}>{t('competition.design.desc')}</a>

                    <div className={css.instList}>
                        <div>
                            <img src={figmaLogo} />
                            <a>Figma</a>
                        </div>

                        <div>
                            <img src={notionLogo} />
                            <a>Notion</a>
                        </div>

                        <div>
                            <img src={chatGptLogo} />
                            <a>ChatGPT</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={css.review} id="reviews">
            <div className={css.sectionName}>
                <h2>{t('reviews.title')}</h2>
                <a>{t('reviews.subtitle')}</a>
            </div>
            
            <div className={css.reviewList}>
                <ReviewBlock 
                    review={t('reviews.review1.text')}
                    name={t('reviews.review1.name')}
                    tag={t('reviews.review1.tag')}
                    link="https://kwork.ru/user/evgen_818"
                    logo={kworkLogo}
                />

                <div className={css.reviewFlex}>
                    <ReviewBlock 
                        review={t('reviews.review2.text')}
                        name={t('reviews.review2.name')}
                        tag={t('reviews.review2.tag')}
                        link="https://kwork.ru/user/t-d-artikov"
                        logo={kworkLogo}
                    />
                    <ReviewBlock 
                        review={t('reviews.review3.text')}
                        name={t('reviews.review3.name')}
                        tag={t('reviews.review3.tag')}
                        link="https://kwork.ru/user/t-d-artikov"
                        logo={kworkLogo}
                    />
                </div>
            </div>
        </div>

        <div className={css.contacts} id="contacts">
            <div className={css.sectionName}>
                <h2>{t('contacts.title')}</h2>
                <a>{t('contacts.subtitle')}</a>
            </div>
            
            <div className={css.contactsBlock}>
                <h4>{t('contacts.taskTitle')}</h4>
                <a className={css.desc}>{t('contacts.taskDesc')}</a>
                
                <div className={css.btns}>
                    <div className={css.btnForm} onClick={() => setShowModal(true)}>{t('contacts.submitRequest')} <i className="fi fi-rr-angle-small-right"></i></div>
                    <a href="https://t.me/tartikov" className={css.telegram} target="_blank" rel="noopener noreferrer">{t('contacts.telegramChannel')} <i className="fi fi-rr-angle-small-right"></i></a>
                </div>
                <a className={css.email}>{t('contacts.emailText')} <a href="mailto:t@tartikov.ru" style={{ textDecoration: "none", color: "#2192ca" }}>t@tartikov.ru</a></a>
            </div>
        </div>
    </MainContent>
  )
}